var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operation_box top-padding"},[_c('top-home',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"ticket_type"},[_c('span',[_vm._v("开票类型:")]),_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(_vm.formData.kplx),callback:function ($$v) {_vm.$set(_vm.formData, "kplx", $$v)},expression:"formData.kplx"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("个人")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("单位")])],1)],1),_c('van-form',{on:{"submit":_vm.onSubmit},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},[(_vm.formData.kplx=='1')?_c('div',[_c('div',{staticClass:"invoive_type"},[_c('span',[_vm._v("发票类型:")]),_c('van-radio-group',{attrs:{"direction":"horizontal","disabled":""},model:{value:(_vm.formData.fplx),callback:function ($$v) {_vm.$set(_vm.formData, "fplx", $$v)},expression:"formData.fplx"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("电子发票")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("专用发票")])],1)],1),_c('van-field',{attrs:{"rules":[{ required: true,trigger:'onSubmit'}],"label":"发票抬头","placeholder":"请输入发票抬头"},model:{value:(_vm.formData.grfptt),callback:function ($$v) {_vm.$set(_vm.formData, "grfptt", $$v)},expression:"formData.grfptt"}}),_c('van-field',{attrs:{"label":"手机号","placeholder":"请输入手机号"},model:{value:(_vm.formData.grfpsjh),callback:function ($$v) {_vm.$set(_vm.formData, "grfpsjh", $$v)},expression:"formData.grfpsjh"}}),_c('van-field',{attrs:{"rules":[
            { required: true, trigger:'onSubmit'},
            { pattern:/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, trigger:'onSubmit', message:'请输入正确的邮箱' }
          ],"label":"邮箱","placeholder":"请输入邮箱"},model:{value:(_vm.formData.grfpyx),callback:function ($$v) {_vm.$set(_vm.formData, "grfpyx", $$v)},expression:"formData.grfpyx"}}),_c('van-field',{attrs:{"label":"开户银行","placeholder":"请输入开户银行"},model:{value:(_vm.formData.grfpkhh),callback:function ($$v) {_vm.$set(_vm.formData, "grfpkhh", $$v)},expression:"formData.grfpkhh"}}),_c('van-field',{attrs:{"label":"开户账号","placeholder":"请输入开户账号"},model:{value:(_vm.formData.grfpkhzh),callback:function ($$v) {_vm.$set(_vm.formData, "grfpkhzh", $$v)},expression:"formData.grfpkhzh"}}),_c('van-field',{attrs:{"label":"地址","placeholder":"请输入地址"},model:{value:(_vm.formData.grkpdz),callback:function ($$v) {_vm.$set(_vm.formData, "grkpdz", $$v)},expression:"formData.grkpdz"}})],1):_c('div',[_c('div',{staticClass:"invoive_type"},[_c('span',[_vm._v("发票类型:")]),_c('van-radio-group',{attrs:{"direction":"horizontal","disabled":""},model:{value:(_vm.formData.fplx),callback:function ($$v) {_vm.$set(_vm.formData, "fplx", $$v)},expression:"formData.fplx"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("电子发票")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("专用发票")])],1)],1),(_vm.formData.fplx=='1')?_c('div',[_c('van-field',{attrs:{"rules":[{ required: true,trigger:'onSubmit'}],"label":"发票抬头","placeholder":"请输入发票抬头"},model:{value:(_vm.formData.dwfptt),callback:function ($$v) {_vm.$set(_vm.formData, "dwfptt", $$v)},expression:"formData.dwfptt"}}),_c('van-field',{attrs:{"rules":[
            { required: true, trigger:'onSubmit'},
            { pattern:/^\d{6,20}$/, trigger:'onSubmit', message:'请输入6-20位' }
          ],"label":"纳税人识别号","placeholder":"请输入纳税人识别号"},model:{value:(_vm.formData.nsrsbh),callback:function ($$v) {_vm.$set(_vm.formData, "nsrsbh", $$v)},expression:"formData.nsrsbh"}}),_c('van-field',{attrs:{"label":"手机号","placeholder":"请输入手机号"},model:{value:(_vm.formData.dwfpsjh),callback:function ($$v) {_vm.$set(_vm.formData, "dwfpsjh", $$v)},expression:"formData.dwfpsjh"}}),_c('van-field',{attrs:{"rules":[
            { required: true, trigger:'onSubmit'},
            { pattern:/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, trigger:'onSubmit', message:'请输入正确的邮箱' }
          ],"label":"邮箱","placeholder":"请输入邮箱"},model:{value:(_vm.formData.dwfpyx),callback:function ($$v) {_vm.$set(_vm.formData, "dwfpyx", $$v)},expression:"formData.dwfpyx"}}),_c('van-field',{attrs:{"label":"开户银行","placeholder":"请输入开户银行"},model:{value:(_vm.formData.dwfpkhh),callback:function ($$v) {_vm.$set(_vm.formData, "dwfpkhh", $$v)},expression:"formData.dwfpkhh"}}),_c('van-field',{attrs:{"label":"开户账号","placeholder":"请输入开户账号"},model:{value:(_vm.formData.dwfpkhzh),callback:function ($$v) {_vm.$set(_vm.formData, "dwfpkhzh", $$v)},expression:"formData.dwfpkhzh"}}),_c('van-field',{attrs:{"label":"地址","placeholder":"请输入地址"},model:{value:(_vm.formData.dwkpdz),callback:function ($$v) {_vm.$set(_vm.formData, "dwkpdz", $$v)},expression:"formData.dwkpdz"}})],1):_c('div',[_c('van-field',{attrs:{"rules":[{ required: true,trigger:'onSubmit'}],"label":"发票抬头","placeholder":"请输入发票抬头"},model:{value:(_vm.formData.dwfptt),callback:function ($$v) {_vm.$set(_vm.formData, "dwfptt", $$v)},expression:"formData.dwfptt"}}),_c('van-field',{attrs:{"rules":[
            { required: true, trigger:'onSubmit'},
            { pattern:/^\d{6,20}$/, trigger:'onSubmit', message:'请输入6-20位' }
          ],"label":"纳税人识别号","placeholder":"请输入纳税人识别号"},model:{value:(_vm.formData.nsrsbh),callback:function ($$v) {_vm.$set(_vm.formData, "nsrsbh", $$v)},expression:"formData.nsrsbh"}}),_c('van-field',{attrs:{"label":"开户银行","placeholder":"请输入开户银行"},model:{value:(_vm.formData.dwfpkhh),callback:function ($$v) {_vm.$set(_vm.formData, "dwfpkhh", $$v)},expression:"formData.dwfpkhh"}}),_c('van-field',{attrs:{"label":"开户账号","placeholder":"请输入开户账号"},model:{value:(_vm.formData.dwfpkhzh),callback:function ($$v) {_vm.$set(_vm.formData, "dwfpkhzh", $$v)},expression:"formData.dwfpkhzh"}}),_c('van-field',{attrs:{"label":"地址","placeholder":"请输入地址"},model:{value:(_vm.formData.dwkpdz),callback:function ($$v) {_vm.$set(_vm.formData, "dwkpdz", $$v)},expression:"formData.dwkpdz"}})],1)]),_c('van-button',{staticStyle:{"width":"70%","height":"40px","padding":"3px 0","fontSize":"16px"},attrs:{"round":"","type":"info","native-type":"submit"}},[_vm._v("提交")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }