<template>
  <div class="operation_box top-padding">
    <top-home :title="title"></top-home>
    <div class="ticket_type">
      <span>开票类型:</span>
      <van-radio-group
        v-model="formData.kplx"
        direction="horizontal"
      >
        <van-radio name="1">个人</van-radio>
        <van-radio name="2">单位</van-radio>
      </van-radio-group>
    </div>

    <van-form
      v-model="formData"
      @submit="onSubmit"
    >
      <div v-if="formData.kplx=='1'">
        <div class="invoive_type">
          <span>发票类型:</span>
          <van-radio-group
            v-model="formData.fplx"
            direction="horizontal"
            disabled
          >
            <van-radio name="1">电子发票</van-radio>
            <van-radio name="2">专用发票</van-radio>
          </van-radio-group>
        </div>
        <van-field
          v-model="formData.grfptt"
          :rules="[{ required: true,trigger:'onSubmit'}]"
          label="发票抬头"
          placeholder="请输入发票抬头"
        />
        <van-field
          v-model="formData.grfpsjh"
          label="手机号"
          placeholder="请输入手机号"
        />
        <van-field
          v-model="formData.grfpyx"
          :rules="[
              { required: true, trigger:'onSubmit'},
              { pattern:/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, trigger:'onSubmit', message:'请输入正确的邮箱' }
            ]"
          label="邮箱"
          placeholder="请输入邮箱"
        />
        <van-field
          v-model="formData.grfpkhh"
          label="开户银行"
          placeholder="请输入开户银行"
        />
        <van-field
          v-model="formData.grfpkhzh"
          label="开户账号"
          placeholder="请输入开户账号"
        />
        <van-field
          v-model="formData.grkpdz"
          label="地址"
          placeholder="请输入地址"
        />
      </div>
      <div v-else>
        <div class="invoive_type">
          <span>发票类型:</span>
          <van-radio-group
            v-model="formData.fplx"
            direction="horizontal"
            disabled
          >
            <van-radio name="1">电子发票</van-radio>
            <van-radio name="2">专用发票</van-radio>
          </van-radio-group>
        </div>
        <div v-if="formData.fplx=='1'">
          <van-field
            v-model="formData.dwfptt"
            :rules="[{ required: true,trigger:'onSubmit'}]"
            label="发票抬头"
            placeholder="请输入发票抬头"
          />
          <van-field
            v-model="formData.nsrsbh"
            :rules="[
              { required: true, trigger:'onSubmit'},
              { pattern:/^\d{6,20}$/, trigger:'onSubmit', message:'请输入6-20位' }
            ]"
            label="纳税人识别号"
            placeholder="请输入纳税人识别号"
          />
          <van-field
            v-model="formData.dwfpsjh"
            label="手机号"
            placeholder="请输入手机号"
          />
          <van-field
            v-model="formData.dwfpyx"
            :rules="[
              { required: true, trigger:'onSubmit'},
              { pattern:/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, trigger:'onSubmit', message:'请输入正确的邮箱' }
            ]"
            label="邮箱"
            placeholder="请输入邮箱"
          />
          <van-field
            v-model="formData.dwfpkhh"
            label="开户银行"
            placeholder="请输入开户银行"
          />
          <van-field
            v-model="formData.dwfpkhzh"
            label="开户账号"
            placeholder="请输入开户账号"
          />
          <van-field
            v-model="formData.dwkpdz"
            label="地址"
            placeholder="请输入地址"
          />
        </div>
        <div v-else>
          <van-field
            v-model="formData.dwfptt"
            :rules="[{ required: true,trigger:'onSubmit'}]"
            label="发票抬头"
            placeholder="请输入发票抬头"
          />
          <van-field
            v-model="formData.nsrsbh"
            :rules="[
              { required: true, trigger:'onSubmit'},
              { pattern:/^\d{6,20}$/, trigger:'onSubmit', message:'请输入6-20位' }
            ]"
            label="纳税人识别号"
            placeholder="请输入纳税人识别号"
          />
          <van-field
            v-model="formData.dwfpkhh"
            label="开户银行"
            placeholder="请输入开户银行"
          />
          <van-field
            v-model="formData.dwfpkhzh"
            label="开户账号"
            placeholder="请输入开户账号"
          />
          <van-field
            v-model="formData.dwkpdz"
            label="地址"
            placeholder="请输入地址"
          />
        </div>
      </div>
      <van-button
        round
        type="info"
        native-type="submit"
        style="width:70%;height:40px;padding:3px 0;fontSize:16px"
      >提交</van-button>
    </van-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getComputerTableApi,
  editComputerTableApi,
  openInvoiceApi
} from '../../../reuqest/api'
export default {
  data () {
    return {
      formData: {
        kplx: '1',
        fplx: '1',
        grfptt: '',
        grfpkhzh: '',
        grfpkhh: '',
        grkpdz: '',
        grfpsjh: '',
        grfpyx: '',
        dwfptt: '',
        dwfpkhzh: '',
        dwfpkhh: '',
        dwkpdz: '',
        dwfpsjh: '',
        dwfpyx: '',
        nsrsbh: ''
      },
      userData: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    title () {
      if (this.$route.query.operationType && this.$route.query.operationType == 'kp') {
        return '补开发票'
      } else {
        return '修改抬头'
      }
    }
  },
  mounted () {
    this.getMyInvoiceData()
  },
  methods: {
    //获取发票信息
    getMyInvoiceData () {
      const loadingToast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      getComputerTableApi('1549579183084244994', { blade_user_id: this.userInfo.user_id })
        .then(res => {
          let data = res.data.records[0]
          this.userData = data
          let fpArr = ['fptt', 'fpkhh', 'fpkhzh', 'fpsjh', 'fpyx', 'kpdz']
          this.formData.kplx = data.kplx
          this.formData.fplx = data.fplx
          if (data.kplx == '1') {
            for (let key of fpArr) {
              this.formData[`gr${key}`] = data[key]
            }
          } else {
            for (let key of fpArr) {
              this.formData[`dw${key}`] = data[key]
            }
            this.formData.nsrsbh = data.nsrsbh
          }
          loadingToast.clear()
        }).catch(() => { })
    },
    onSubmit () {
      const loadingToast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      let fpArr = ['fptt', 'fpkhh', 'fpkhzh', 'fpsjh', 'fpyx', 'kpdz']
      let fpData = {
        kplx: this.formData.kplx,
        fplx: this.formData.fplx
      }
      if (this.formData.kplx == '1') {
        for (let key of fpArr) {
          fpData[key] = this.formData[`gr${key}`]
        }
      } else {
        for (let key of fpArr) {
          fpData[key] = this.formData[`dw${key}`]
        }
        fpData.nsrsbh = this.formData.nsrsbh
      }
      if (this.$route.query.operationType && this.$route.query.operationType == 'kp') {//开票
        let data = {
          ...fpData,
          id: this.$route.query.zdjlId
        }
        openInvoiceApi(data).then(() => {
          loadingToast.clear()
          this.$toast.success('开票成功')
          // this.$router.back(0)
        }).catch(() => {
          loadingToast.clear()
          this.$toast.success('开票失败')
        })
      } else {//修改开票信息
        let data = {
          ...this.userData,
          ...fpData,
        }
        editComputerTableApi('1549579183084244994', data)
          .then(() => {
            this.$toast.success('修改成功')
            loadingToast.clear()
          }).catch(() => {
            loadingToast.clear()
            this.$toast.success('修改失败')
          })
      }
    }
  }
}
</script>
 
<style lang = "less" scoped>
.operation_box {
  height: 100vh;
  background-color: rgb(245, 245, 245);
  .ticket_type {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: #323233;
    font-size: 14px;
    line-height: 24px;
    background-color: white;
  }
  .invoive_type {
    display: flex;
    justify-content: space-between;
    color: #323233;
    padding: 10px;
    font-size: 14px;
    line-height: 24px;
  }
  .van-button {
    width: 200px;
    border-radius: 100px;
    margin: 30px 0;
  }
}
</style>